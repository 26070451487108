.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* Adjust width as needed */
  vertical-align: middle;
}

.tableContainer {
  height: 60vh;
  display: flex;
  flex-direction: column;
}

.table {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.pagination {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.input {
  font-size: 13px;
  line-height: 1;
  height: auto;
  padding: 0.2rem 0.5rem;
}

.pagination button {
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
}
.pagination button[disabled] {
  opacity: 0.5;
  cursor: default;
}

.pagination .previous,
.pagination .next {
  flex: 1 1;
  text-align: center;
}
.pagination .center {
  flex: 1.5 1;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.pagination .pageInfo {
  display: flex;
  align-items: center;
  margin: 3px 10px;
  white-space: nowrap;
}
.pagination .pageSizeOption {
  margin: 3px 10px;
}

.title {
  margin-top: 10px;
  margin-bottom: 5px;
}

.header {
  text-align: left;
}

.text {
  margin-left: 20px;
}

.table {
  font-size: smaller;
}

.tablePadding {
  padding: 20px;
}

.tableInModal {
  margin-top: 20px;
}
