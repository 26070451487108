.confirmation {
  font-weight: 700;
}

.pdf {
  margin-bottom: 16px;
}

.spinner{
  align-self: center
}