.content {
  text-align: left;
}

.content label {
  font-weight: 500;
}

.subtitle {
  text-align: left;
  margin-bottom: 1rem;
}

.title {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 30px;
}

.bd_callout {
  text-align: left !important;
  padding: 1.25rem;
  margin-bottom: 1.25rem;
  border-left-color: #d9534f;
  border-left-width: .25rem;
  border-radius: .25rem;
  /* font-family: "Titillium Web"; */
}
