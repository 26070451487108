.left {
  padding-left: 50px;
}

.user {
  padding-left: 50px;
  margin-top: 10px;
}

.text {
  margin-left: 5px;
}

.key {

  font-size: 0.8rem;
  color: blue;
  font-weight: bolder;
  text-align: left;
  text-transform: capitalize;


}

.key_right {

  font-size: 0.8rem;
  color: blue;
  font-weight: bolder;
  text-align: right;
  text-transform: capitalize;


}

.redkey {

  font-size: 0.8rem;
  color: red;
  font-weight: bolder;
  text-align: left;
  text-transform: capitalize;


}

.head_k {

  font-size: 0.8rem;
  color: black;
  text-align: right;
  text-transform: capitalize;

}

.head_v {

  font-size: 0.8rem;
  color: black;
  text-align: left;
  text-transform: capitalize;

}

.head_k_b {

  font-size: 0.8rem;
  color: black;
  font-weight: bold;
  text-align: right;
  text-transform: capitalize;

}

.head_v_b {

  font-size: 0.8rem;
  color: black;
  font-weight: bold;
  text-align: left;
  text-transform: capitalize;

}

.value {

  font-size: 0.8rem;
  color: black;
  font-weight: bolder;
  text-align: left;
  text-transform: capitalize;

}

.left {

  color: black;
  font-weight: bold;
  text-align: left;

}

.hidden {

  display: none;

}


.logo {
  height: 80px;
}

.navbar {
  background-color: #f3f3f3;
  margin-bottom: 30px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.text {
  font-size: 16px;
  font-weight: 700;
}

.project {
  font-size: 16px;
  font-weight: 700;
  white-space: pre-wrap;
}


.h3 {
  font-size: 14px;
  font-weight: 700;
  /* margin-top: 10px; */
}

.h4 {
  font-size: 14px;
  /* font-weight: 700; */
  margin-top: 10px;
}

.btn {
  padding-top: 1px;
  padding-bottom: 1px;
}

.circleBtn {
  border-radius: 50%;
  padding:10px;
}

.squareBtn {
  border-radius: 2px;
  padding:8px;
  margin-top: 3px;
}

.checkbox {
  margin-top: 6px;
}

.confirmBtn {
  padding-left: 23px;
  padding-right: 23px;
}
.cloneBtn {
  padding-left: 30px;
  padding-right: 30px;
}
