.App {
  text-align: center;
}

:root {
  --bs-body-line-height: 1.224;
  --bs-body-font-size: "15";
}

.form-control {
  font-size: 13px;
  line-height: 1;
  height: auto;
  padding: 0.3rem 0.3rem;
}

.btn {
  font-size: 15px;
  --bs-btn-line-height: 1;
  --bs-btn-padding-x: 0.5rem;
}

.table td {
  padding: 0.3rem 0.3rem;
}

.form-select {
  font-size: 15px;
  line-height: 1;
  padding: 0.3rem 0.3rem;
}
